<template>
  <div class="space-y-4">
    <div>
      <label for="preferred-console" class="block text-sm font-medium mb-1 text-secondary">
        {{ $t('gamerInfoStep.console.label') }}
      </label>
      <div class="grid grid-cols-2 gap-4">
        <OptionCard
          v-for="consoleOption in consoles"
          :key="consoleOption.id"
          :logo="consoleOption.logo"
          :title="consoleOption.shortName"
          :color="consoleOption.color"
          :isSelected="console.id === consoleOption.id"
          size="sm"
          containerClassName="w-full h-30"
          titleClassName="text-md uppercase pt-1"
          @onClick="handleConsoleSelect(consoleOption)"
        />
      </div>

      <div v-if="console.id === 'other'" class="mt-4">
        <input
          ref="customConsoleInput"
          v-model="customConsoleName"
          type="text"
          :placeholder="$t('gamerInfoStep.console.customName.placeholder')"
          class="w-full p-2 rounded bg-primary border border-tertiary/30 text-white"
        />
      </div>
    </div>

    <div
      v-if="selectedConsole && selectedConsole.id === 'pc'"
      class="block text-sm font-medium mb-1 text-secondary"
    >
    <span>{{ $t('gamerInfoStep.console.noSubscriptionNeeded') }}</span>
    </div>

    <!-- Subscription section -->
    <div
      v-if="selectedConsole && selectedConsole.id !== 'other' && selectedConsole.id !== 'pc' && selectedConsole.subscription"
      class="space-y-4"
    >
      <label :for="selectedConsole.subscription.playerIdentifierLabel" class="block text-sm font-medium mb-1 text-secondary">
        {{ $t('gamerInfoStep.console.subscription.label', { name: selectedConsole.subscription.name }) }}
      </label>
      <div class="grid grid-cols-2 gap-4">
        <OptionCard
          title="Yes"
          color="green"
          :isSelected="subscriptionActive === true"
          @onClick="setSubscriptionStatus(true)"
        />
        <OptionCard
          title="No"
          color="red"
          :isSelected="subscriptionActive === false"
          @onClick="setSubscriptionStatus(false)"
        />
      </div>

      <div v-if="subscriptionActive === false" class="mt-2 text-red-600 text-sm">
        {{ $t('gamerInfoStep.console.subscriptionWarning') }}
      </div>

      <div v-if="subscriptionActive === true && selectedConsole.subscription">
        <label :for="selectedConsole.subscription.playerIdentifierLabel" class="block text-sm font-medium mb-1 text-secondary">
          {{ $t('gamerInfoStep.console.onlineId.label', { platform: selectedConsole.subscription.name }) }}
        </label>
        <input
          type="text"
          :id="selectedConsole.subscription.playerIdentifierLabel"
          v-model="onlineId"
          ref="onlineIdInput"
          :placeholder="$t('gamerInfoStep.console.onlineId.placeholder', { platform: selectedConsole.subscription.name })"
          class="w-full p-2 rounded bg-primary border border-tertiary/30 text-white"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import OptionCard from './OptionCard.vue'

export default {
  name: 'ConsoleSelection',
  components: { OptionCard },

  computed: {
    ...mapState('multiStepForm', {
      formData: (state) => state.formData,
    }),
    ...mapGetters('multiStepForm', ['CONSOLES']),

    consoles() {
      return this.CONSOLES
    },

    console: {
      get() {
        return this.formData.console || { id: null, customName: '' }
      },
      set(value) {
        this.updateFormData({ field: 'console', value })
      },
    },

    customConsoleName: {
      get() {
        return this.console.customName || ''
      },
      set(value) {
        this.updateFormData({
          field: 'console',
          value: { ...this.console, customName: value },
        })
      },
    },

    selectedConsole() {
      return this.consoles.find((console) => console.id === this.console.id) || {}
    },

    subscriptionActive: {
      get() {
        return this.formData.onlineSubscription.hasSubscription === undefined
          ? null
          : this.formData.onlineSubscription.hasSubscription
      },
      set(value) {
        this.updateFormData({
          field: 'onlineSubscription',
          value: {
            hasSubscription: value,
            subscriptionType: value ? this.console.subscription?.id : null,
          },
        })
      },
    },

    onlineId: {
      get() {
        return this.formData.onlineId
      },
      set(value) {
        this.updateFormData({ field: 'onlineId', value })
      },
    },
  },

  methods: {
    ...mapActions('multiStepForm', ['updateFormData']),

    handleConsoleSelect(consoleOption) {
      this.console = {
        id: consoleOption.id,
        customName: consoleOption.id === 'other' ? this.customConsoleName : '',
      }
      // Reset subscriptionActive to null to have no selection by default
      this.subscriptionActive = null
      this.onlineId = ''
    },

    setSubscriptionStatus(status) {
      this.subscriptionActive = status
      if (!status) {
        this.onlineId = ''
      } else {
        this.$nextTick(() => {
          this.$refs.onlineIdInput && this.$refs.onlineIdInput.focus()
        })
      }
    },
  },

}
</script>
