<template>
  <div class="space-y-4">
    <!-- Previous template code remains the same -->
    <h2 class="text-lg font-semibold text-white mb-0 uppercase">
      <span v-html="title"></span>
    </h2>

    <!-- Calendly Link Button -->
    <div class="pt-4 space-y-4">
      <p class="block text-sm font-thin text-white">
        <span v-html="$t('thankYouStep.interviewDescription')"></span>
      </p>
      <div class="bg-primary pt-1 pb-5 px-4 rounded-lg space-y-3">
        <p class="block text-sm font-thin text-secondary">
          <span v-html="$t('thankYouStep.performanceDescription')"></span>
        </p>
        <p class="block text-sm font-thin text-white">
          <span v-html="$t('thankYouStep.divisionAssignment')"></span>
        </p>
        <p class="block text-sm font-thin text-white">
          <span v-html="$t('thankYouStep.clubMembership')"></span>
        </p>
      </div>
      <div class="space-y-3 pt-2">
        <p class="block text-xs font-thin text-white text-center max-w-xs m-auto">
          <span v-html="$t('thankYouStep.schedulePrompt')"></span>
        </p>

        <button
            @click.prevent="initCalendlyPopup"
            class="relative bg-accent hover:bg-white text-white hover:text-primary font-bold py-4 px-6 rounded-lg uppercase w-full lg:max-w-md transition duration-300 flex items-center justify-center"
          >
          {{ $t('thankYouStep.scheduleGame') }}
        </button>
      </div>
    </div>

    <div
      v-if="showModal" class="fixed inset-0 z-50 flex items-center justify-center bg-white bg-opacity-40 px-4"
      style="margin-top: 0px;"
    >
      <!-- Backdrop -->
      <div class="absolute inset-0 bg-black/50" @click="showModal = true"></div>

      <!-- Modal -->
      <div
        class="relative bg-primary p-6 rounded-lg w-full max-w-md mx-4 space-y-4"
      >
        <div class="space-y-2">
          <h3 class="text-lg font-medium text-white">
            {{ $t('thankYouStep.modalTitle') }}
          </h3>
          <p class="text-sm text-secondary">
            {{ $t('thankYouStep.modalDescription') }}
          </p>
        </div>

        <div class="bg-primary-light px-3 py-2 relative flex items-center">
          <span class="text-white text-sm truncate whitespace-nowrap overflow-hidden flex-1">{{ inviteUrl }}</span>
          <button
            class="absolute right-0 top-0 bottom-0 py-2 px-4 text-white bg-accent font-medium shadow-lg transition-all flex space-x-2 items-center"
            :class="{ 'bg-accent': !isCopied, 'bg-greenFC': isCopied }"
            @click="copyInviteLink"
          >
            <span class="text-sm">{{ copyButtonText }}</span>
            <Icon :path="currentIcon" :size="18" :color="iconColor" />
          </button>
        </div>
        <button
          class="flex-1 px-4 py-2 bg-transparent text-greenFC rounded-lg hover:opacity-90 transition-colors w-full"
          @click="goHome"
        >
          {{ $t('thankYouStep.goHome') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Icon from '@/components/Icon/Icon.vue'
import { colors } from '@/shared/theme'
import { mdiContentCopy, mdiCheck } from '@mdi/js'

export default {
  name: 'ThankYouStep',

  components: {
    Icon,
  },

  computed: {
    ...mapState('multiStepForm', ['formData']),
    ...mapGetters('multiStepForm', ['showFinishMessage']),
    title() {
      return this.$t('thankYouStep.title', { name: this.formData.name })
    },
    email: {
      get() {
        return this.formData.email
      },
    },
    currentIcon() {
      return this.isCopied ? mdiCheck : mdiContentCopy
    },
    iconColor() {
      return colors.white
    },
    inviteUrl() {
      const currentUrl = window.location.href
      const baseUrl = currentUrl.split('?')[0] // Remove any existing query params
      return `${baseUrl}?invited=${this.email}`
    },
    copyButtonText() {
      return this.isCopied ? this.$t('thankYouStep.linkCopied') : this.$t('thankYouStep.copyLink')
    },
  },

  data() {
    return {
      calendlyScript: null,
      calendlyStyleSheet: null,
      enVideo: {
        src: 'https://play.vidyard.com/4J8pEdZtkaLN3bBxqFBbNZ.jpg',
        uuid: '4J8pEdZtkaLN3bBxqFBbNZ',
      },
      esVideo: {
        src: 'https://play.vidyard.com/cXq7eQZPqgtqXwcXHt3gzh.jpg',
        uuid: 'cXq7eQZPqgtqXwcXHt3gzh',
      },
      showModal: false,
      isCopied: false,
      copyTimeout: null,
    }
  },

  watch: {
    showFinishMessage: {
      immediate: true,
      handler(newValue) {
        this.showModal = newValue
      },
    },
  },

  methods: {
    loadCalendlyWidget() {
      // Add Calendly stylesheet
      this.calendlyStyleSheet = document.createElement('link')
      this.calendlyStyleSheet.href = 'https://assets.calendly.com/assets/external/widget.css'
      this.calendlyStyleSheet.rel = 'stylesheet'
      document.head.appendChild(this.calendlyStyleSheet)

      // Add Calendly script
      this.calendlyScript = document.createElement('script')
      this.calendlyScript.src = 'https://assets.calendly.com/assets/external/widget.js'
      this.calendlyScript.async = true
      document.body.appendChild(this.calendlyScript)
    },
    initCalendlyPopup() {
      if (window.Calendly) {
        window.Calendly.initPopupWidget({
          url: 'https://calendly.com/uleagueapp/40min?hide_event_type_details=1&hide_gdpr_banner=1&background_color=011526&text_color=a7cbd9&primary_color=fff',
        })
      }
    },
    markStepAsCompleted() {
      this.$emit('step-completed')
      this.$store.dispatch('multiStepForm/setValidationStatus', true)
    },
    async copyInviteLink() {
      try {
        await navigator.clipboard.writeText(this.inviteUrl)
        this.isCopied = true
        // Clear any existing timeout
        if (this.copyTimeout) {
          clearTimeout(this.copyTimeout)
        }
        // Reset after 3 seconds
        this.copyTimeout = setTimeout(() => {
          this.isCopied = false
        }, 3000)
      } catch (err) {
        console.error('Failed to copy text: ', err)
      }
    },
    goHome() {
      this.$router.push('/')
    },
  },

  mounted() {
    this.loadCalendlyWidget()
    this.markStepAsCompleted()
  },

  beforeDestroy() {
    // Cleanup styles and scripts
    if (this.calendlyStyleSheet && this.calendlyStyleSheet.parentNode) {
      this.calendlyStyleSheet.parentNode.removeChild(this.calendlyStyleSheet)
    }
    if (this.calendlyScript && this.calendlyScript.parentNode) {
      this.calendlyScript.parentNode.removeChild(this.calendlyScript)
    }
  },
}
</script>

<style scoped>
.calendar-button {
  display: inline-block;
}

.calendar-iframe-container {
  background-color: white;
  border-radius: 0.5rem;
  overflow: hidden;
  margin-bottom: 1.5rem;
}
</style>
