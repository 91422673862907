<template>
  <div class="space-y-4">
    <h2 class="text-lg font-semibold text-white uppercase">
      <span v-html="title"></span>
    </h2>

    <div class="pt-0 space-y-4">
      <p class="block text-sm font-thin text-white">
        <span v-html="$t('joinNextTournamentStep.description', { tournamentName: nextTournament ? nextTournament.name : '...' })"></span>
      </p>
    </div>

    <div class="space-y-3 pt-2">
      <div
        class="flex justify-center"
      >
        <Transition name="fade" mode="out-in">
          <TournamentCard v-if="nextTournament" :tournament="nextTournament" key="tournament" />
          <div v-else class="w-full h-72 bg-primary rounded-lg" key="loading"></div>
        </Transition>
      </div>
    </div>

    <div class="pt-4 space-y-4">
      <p class="block text-center text-sm font-thin text-white">
        <span v-html="$t('joinNextTournamentStep.copyAccessCodeInstructions')"></span>
      </p>
    </div>

    <div class="bg-primary relative flex items-center max-w-xs mx-auto">
      <span class="text-white text-center text-lg truncate whitespace-nowrap overflow-hidden flex-1">ULEAGUE</span>
      <button
        class="absolute_ right-0 top-0 bottom-0 py-2 px-4 h-10 text-white bg-accent font-medium shadow-lg transition-all flex space-x-2 items-center"
        :class="{ 'bg-accent': !isCopied, 'bg-greenFC': isCopied }"
        @click="copyAccessCode"
      >
        <span class="text-sm">{{ copyButtonText }}</span>
        <Icon :path="currentIcon" :size="18" :color="iconColor" />
      </button>
    </div>

    <!-- Join the tournament using QR Code or Button -->
    <div class="flex flex-col p-4">
      <div class="hidden md:block">
        <p
          class="font-thin text-white text-sm text-center pb-4 px-10 leading-6"
          v-html="$t('joinNextTournamentStep.qrScanMessage')"
        />
        <div class="m-auto flex justify-center items-center">
          <canvas ref="qrCanvas"></canvas>
        </div>
      </div>
      <div class="flex flex-col justify-center w-full md:hidden">
        <p
          class="font-thin text-white text-sm text-center pb-2 px-10 leading-6"
          v-html="$t('joinNextTournamentStep.qrScanMessageButton')"
        />
        <button
          @click="register"
          class="relative bg-accent hover:bg-white text-white hover:text-primary font-bold py-4 px-6 rounded-lg uppercase w-full lg:max-w-md transition duration-300 flex items-center justify-center"
        >
          {{ $t('joinNextTournamentStep.register') }}
        </button>
      </div>
    </div>

    <!-- Already registered checkbox -->
    <div>
      <div class="flex items-center space-x-2">
        <input
          type="checkbox"
          id="alreadyRegisteredInTournament"
          v-model="alreadyRegisteredInTournament"
          class="h-4 w-4 rounded border-gray-300"
        />
        <label for="alreadyRegisteredInTournament" class="text-sm text-secondary">
          {{ $t('joinNextTournamentStep.alreadyRegistered') }}
        </label>
      </div>
    </div>

    <div
      v-if="showModal" class="fixed inset-0 z-50 flex items-center justify-center bg-white bg-opacity-40"
      style="margin-top: 0px;"
    >
      <!-- Backdrop -->
      <div class="absolute inset-0 bg-black/50" @click="showModal = false"></div>

      <!-- Modal -->
      <div
        class="relative bg-primary p-6 rounded-lg w-full max-w-md mx-4 space-y-4"
      >
        <div class="space-y-2">
          <h3 class="text-lg font-medium text-white">
            {{ $t('joinNextTournamentStep.modal.title') }}
          </h3>
          <p class="text-sm text-secondary">
            {{ $t('joinNextTournamentStep.modal.description') }}
          </p>
        </div>

        <div class="grid grid-cols-2 space-x-4">
          <button
            class="py-2 px-4 text-white rounded-md font-medium shadow-lg transition-all w-full bg-accent"
            @click="openJoinTournamentLink"
          >
            {{ $t('joinNextTournamentStep.modal.action') }}
          </button>
          <button
            class="flex-1 px-4 py-2 bg-gray-600 text-white rounded-lg hover:opacity-90 transition-colors w-full"
            @click="showModal = false"
          >
            {{ $t('joinNextTournamentStep.modal.cancel') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { colors } from '@/shared/theme'
import { mdiContentCopy, mdiCheck } from '@mdi/js'
import TournamentCard from '@/components/TournamentCard/TournamentCard'
import { getNextTournament } from '@/db/tournaments'
import QRCode from 'qrcode'
import Icon from '@/components/Icon/Icon.vue'

export default {
  name: 'JoinNextTournament',

  components: {
    TournamentCard,
    Icon,
  },

  computed: {
    ...mapState('multiStepForm', ['formData']),
    ...mapGetters('multiStepForm', ['showFinishMessage']),
    title() {
      return this.$t('joinNextTournamentStep.title', { name: this.formData.name })
    },
    tournamentUrl() {
      return this.nextTournament ? this.nextTournament.url : ''
    },
    email: {
      get() {
        return this.formData.email
      },
    },
    currentIcon() {
      return this.isCopied ? mdiCheck : mdiContentCopy
    },
    iconColor() {
      return colors.white
    },
    inviteUrl() {
      const currentUrl = window.location.href
      const baseUrl = currentUrl.split('?')[0] // Remove any existing query params
      return `${baseUrl}?invited=${this.email}`
    },
    copyButtonText() {
      return this.isCopied ? this.$t('joinNextTournamentStep.copiedAccessCode') : this.$t('joinNextTournamentStep.copyAccessCode')
    },
    alreadyRegisteredInTournament: {
      get() {
        return this.formData.alreadyRegisteredInTournament || false
      },
      set(value) {
        this.updateFormData({ field: 'alreadyRegisteredInTournament', value })
      },
    },
    showAlreadyRegisteredError() {
      return this.alreadyRegisteredInTournamentTouched && !this.alreadyRegisteredInTournament
    },
  },

  data() {
    return {
      showModal: false,
      isCopied: false,
      copyTimeout: null,
      loading: false,
      nextTournament: null,
      alreadyRegisteredInTournamentTouched: false,
      accessCode: 'ULEAGUE',
    }
  },

  watch: {
    alreadyRegisteredInTournament(_) {
      this.alreadyRegisteredInTournamentTouched = true
      this.validateStep()
    },
  },

  methods: {
    ...mapActions('multiStepForm', ['updateFormData']),
    ...mapMutations('multiStepForm', ['setValidationStatus']),
    async loadNextTournament() {
      if (this.loading) {
        return
      }

      this.loading = true

      const nextTournament = await getNextTournament()

      if (nextTournament) {
        this.nextTournament = nextTournament
        this.generateQRCode()
      }
      this.loading = false
    },

    openJoinTournamentLink() {
      this.validateStep()

      // Open download link in new tab
      window.open(this.tournamentUrl, '_blank')

      this.showModal = false
    },

    register() {
      this.showModal = true
    },

    generateQRCode() {
      const canvas = this.$refs.qrCanvas
      QRCode.toCanvas(canvas, this.tournamentUrl, {
        width: 160,
        margin: 2,
        color: {
          dark: '#FFFFFF',
          light: '#000000',
        },
      })
    },

    markStepAsCompleted() {
      this.$emit('step-completed')
      this.$store.dispatch('multiStepForm/setValidationStatus', true)
    },

    validateStep() {
      const isValid = this.alreadyRegisteredInTournament
      this.setValidationStatus(isValid)
    },

    async copyAccessCode() {
      try {
        await navigator.clipboard.writeText(this.accessCode)
        this.isCopied = true
        // Clear any existing timeout
        if (this.copyTimeout) {
          clearTimeout(this.copyTimeout)
        }
        // Reset after 3 seconds
        this.copyTimeout = setTimeout(() => {
          this.isCopied = false
        }, 3000)
      } catch (err) {
        console.error('Failed to copy text: ', err)
      }
    },
  },

  mounted() {
    this.loadNextTournament()
    this.validateStep()
  },
}
</script>
