<template>
  <div class="pb-4 px-4 grid grid-cols-8">
    <p class="grid col-start-3 col-span-4 text-white text-sm md:text-sm font-extralight text-center">
      {{ $t('onboardingHeader.description') }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'OnboardingHeader',
}
</script>
