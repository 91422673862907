<template>
  <div class="space-y-6">
    <!-- Download App Section -->
    <div class="mb-8">
      <h2 class="text-lg font-semibold text-white mb-4">{{ $t('downloadAppStep.title') }}</h2>

      <!-- Logo -->
      <div class="flex justify-center mb-4">
        <inline-svg
          :src="require('@/assets/logos/uleague-logo.svg')"
          title="ULEAGUE Logo"
          class="app-logo bg-white"
        ></inline-svg>
      </div>

      <div class="bg-secondary/10 text-white rounded-lg space-y-4">
        <!-- Main Message -->
        <p class="text-sm leading-relaxed">{{ $t('downloadAppStep.description') }}</p>

        <!-- Highlights -->
        <div class="space-y-2">
          <h3 class="font-medium text-sm text-secondary">{{ $t('downloadAppStep.profileQuestion') }}</h3>
          <div class="grid grid-cols-3 gap-4">
            <OptionCard
              v-for="profileOption in profileOptions"
              :key="profileOption.id"
              :title="$t(`downloadAppStep.profile.${profileOption.id}`)"
              :color="profileOption.color"
              :isSelected="selectedProfile === profileOption.id"
              size="sm"
              @onClick="handleProfileSelect(profileOption.id)"
            />
          </div>
          <transition name="fade" mode="out-in">
            <div v-if="selectedProfileDescription.length" class="pl-1 pt-4 space-y-2 text-white text-sm">
              <div v-for="(description, index) in selectedProfileDescription" :key="index">
                {{ $t(description) }}
              </div>
            </div>
          </transition>
        </div>

        <!-- Admin Community Question -->
        <div v-if="showCommunityQuestion" class="space-y-4">
          <label for="community-status" class="block text-sm font-medium text-secondary">
            {{ $t('downloadAppStep.community.label') }}
          </label>
          <input
            id="community-status"
            v-model="adminCommunityDetails"
            type="text"
            ref="adminCommunityDetails"
            :placeholder="$t('downloadAppStep.community.placeholder')"
            @blur="validateadminCommunityDetails"
            :class="[
              'w-full p-2 rounded bg-primary text-white text-xl',
            ]"
          />
        </div>
      </div>
    </div>

    <!-- Accept Invitation QR Code or Button -->
    <div class="flex flex-col">
      <div class="hidden md:block">
        <p class="text-white text-xs text-center pb-4 px-10">
          {{ $t('downloadAppStep.qrScanMessage') }}
        </p>
        <div class="m-auto flex justify-center items-center">
          <canvas ref="qrCanvas"></canvas>
        </div>
      </div>
      <div class="flex justify-center w-full md:hidden">
        <button
          @click="downloadApp"
          class="relative bg-accent hover:bg-white text-white hover:text-primary font-bold py-4 px-6 rounded-lg uppercase w-full lg:max-w-md transition duration-300 flex items-center justify-center"
        >
          {{ $t('downloadAppStep.downloadApp') }}
        </button>
      </div>
    </div>

    <!-- already downloaded app -->
    <div>
      <div class="flex items-center space-x-2">
        <input
          type="checkbox"
          id="alreadyDownloadedApp"
          v-model="alreadyDownloadedApp"
          class="h-4 w-4 rounded border-gray-300"
        />
        <label for="alreadyDownloadedApp" class="text-sm text-secondary">
          {{ $t('downloadAppStep.alreadyDownloadedApp.label') }}
        </label>
      </div>
      <div class="mt-1 text-sm text-red-500 pt-2" v-if="showAlreadyDownloadedAppError">
        {{ $t('downloadAppStep.alreadyDownloadedApp.error') }}
      </div>
    </div>

    <div
      v-if="showModal" class="fixed inset-0 z-50 flex items-center justify-center bg-white bg-opacity-40"
      style="margin-top: 0px;"
    >
      <!-- Backdrop -->
      <div class="absolute inset-0 bg-black/50" @click="showModal = false"></div>

      <!-- Modal -->
      <div
        class="relative bg-primary p-6 rounded-lg w-full max-w-md mx-4 space-y-4"
      >
        <div class="space-y-2">
          <h3 class="text-lg font-medium text-white">
            {{ $t('downloadAppStep.modal.title') }}
          </h3>
          <p class="text-sm text-secondary">
            {{ $t('downloadAppStep.modal.description') }}
          </p>
        </div>

        <div class="grid grid-cols-2 space-x-4">
          <button
            class="py-2 px-4 text-white bg-accent rounded-md font-medium shadow-lg transition-all w-full"
            @click="openDownloadLink"
          >
            {{ $t('downloadAppStep.modal.action') }}
          </button>
          <button
            class="flex-1 px-4 py-2 bg-gray-600 text-white rounded-lg hover:opacity-90 transition-colors w-full"
            @click="showModal = false"
          >
            {{ $t('downloadAppStep.modal.cancel') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import QRCode from 'qrcode'
import OptionCard from '../components/OptionCard.vue'
import InlineSvg from 'vue-inline-svg'
import { ONELINK_DOWNLOAD_APP_LINK, PROFILE_OPTIONS, PROFILE_DESCRIPTIONS, PROFILE_TYPES } from '@/shared/constants'

export default {
  name: 'DownloadAppStep',

  components: {
    OptionCard,
    InlineSvg,
  },

  data() {
    return {
      alreadyDownloadedAppTouched: false,
      showModal: false,
      DOWNLOAD_APP_URL: ONELINK_DOWNLOAD_APP_LINK,
      profileOptions: PROFILE_OPTIONS,
      adminCommunityDetailsTouched: false,
    }
  },

  computed: {
    ...mapState('multiStepForm', {
      formData: state => state.formData,
    }),

    selectedProfile: {
      get() {
        return this.formData.selectedProfile || ''
      },
      set(value) {
        this.updateFormData({ field: 'selectedProfile', value })
      },
    },

    adminCommunityDetails: {
      get() {
        return this.formData.adminCommunityDetails || ''
      },
      set(value) {
        this.updateFormData({ field: 'adminCommunityDetails', value })
      },
    },

    selectedProfileDescription() {
      return PROFILE_DESCRIPTIONS[this.selectedProfile] || []
    },

    alreadyDownloadedApp: {
      get() {
        return this.formData.alreadyDownloadedApp || false
      },
      set(value) {
        this.updateFormData({ field: 'alreadyDownloadedApp', value })
      },
    },

    showAlreadyDownloadedAppError() {
      return this.alreadyDownloadedAppTouched && !this.alreadyDownloadedApp
    },

    showCommunityQuestion() {
      return this.selectedProfile === PROFILE_TYPES.admin || this.selectedProfile === PROFILE_TYPES.player
    },
  },

  mounted() {
    this.generateQRCode()
    // Validate step on mount to ensure the continue button is correctly enabled/disabled
    this.validateStep()
  },

  methods: {
    ...mapActions('multiStepForm', ['updateFormData']),
    ...mapMutations('multiStepForm', ['setValidationStatus']),

    handleProfileSelect(profileId) {
      this.selectedProfile = profileId
      this.validateStep()
    },

    downloadApp() {
      this.showModal = true
    },

    generateQRCode() {
      const canvas = this.$refs.qrCanvas
      QRCode.toCanvas(canvas, this.DOWNLOAD_APP_URL, {
        width: 160,
        margin: 2,
        color: {
          dark: '#FFFFFF',
          light: '#000000',
        },
      })
    },

    openDownloadLink() {
      this.validateStep()

      // Open download link in new tab
      window.open(this.DOWNLOAD_APP_URL, '_blank')

      // Close modal
      this.showModal = false
    },

    validateadminCommunityDetails() {
      this.adminCommunityDetailsTouched = true
      this.validateStep()
    },

    validateStep() {
      const isValid = this.selectedProfile && this.alreadyDownloadedApp
      this.setValidationStatus(isValid)
    },
  },

  watch: {
    alreadyDownloadedApp(newValue) {
      this.alreadyDownloadedAppTouched = true
      this.validateStep()
    },

    formData: {
      handler() {
        this.validateStep()
      },
      deep: true,
    },
  },
}
</script>

<style scoped>
button[disabled] {
  opacity: 0.7;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.app-logo {
  width: 100px;
  height: 100px;
  border-radius: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.list-disc {
  list-style-type: disc;
}

/* Add styles for markdown rendering */
:deep(strong) {
  font-weight: 600;
}

:deep(em) {
  font-style: italic;
}

.fixed {
  position: fixed;
}

.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.z-50 {
  z-index: 50;
}
</style>
