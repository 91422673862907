<template>
  <div class="p-4 md:p-6 bg-primary-light border-tertiary border-opacity-25 border-t rounded-lg relative min-h-[200px]">
    <transition name="fade" mode="out-in">
      <component :is="currentStepComponent" :key="currentStep" />
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PersonalInfoStep from '../steps/PersonalInfoStep.vue'
import GamerInfoStep from '../steps/GamerInfoStep.vue'
import DiscordInviteStep from '../steps/DiscordInviteStep.vue'
import DownloadAppStep from '../steps/DownloadAppStep.vue'
import ThankYouStep from '../steps/ThankYouStep.vue'
import JoinNextTournament from '../steps/JoinNextTournament.vue'

export default {
  components: {
    PersonalInfoStep,
    GamerInfoStep,
    DiscordInviteStep,
    DownloadAppStep,
    JoinNextTournament,
    ThankYouStep,
  },
  computed: {
    ...mapState('multiStepForm', ['currentStep']),
    currentStepComponent() {
      const components = {
        1: 'PersonalInfoStep',
        2: 'GamerInfoStep',
        3: 'DiscordInviteStep',
        4: 'DownloadAppStep',
        5: 'JoinNextTournament',
        6: 'ThankYouStep',
      }
      return components[this.currentStep]
    },
  },
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
