<template>
  <div class="space-y-4">
    <FormHeader
      :title="$t('gamerInfoStep.title')"
    />
    <div class="space-y-4">
      <VideoGameSelection />
      <ConsoleSelection />
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import FormHeader from '../components/FormHeader.vue'
import VideoGameSelection from '../components/VideoGameSelection.vue'
import ConsoleSelection from '../components/ConsoleSelection.vue'
import { mapState, mapMutations, mapGetters } from 'vuex'
import debounce from 'lodash/debounce'

export default {
  name: 'GamerInfoStep',
  components: {
    FormHeader,
    VideoGameSelection,
    ConsoleSelection,
  },

  data() {
    return {
      isInitialized: false,
      touchedFields: {
        videoGame: false,
        gameMode: false,
        console: false,
        customConsoleName: false,
        onlineSubscription: false,
        onlineId: false,
      },
      formSubmitAttempted: false,
    }
  },

  validations: {
    formData: {
      videoGame: { required },
      gameMode: {
        id: { required },
        clubName: {
          required: function(value) {
            return this.formData.gameMode.id === 'clubs' ? required(value) : true
          },
        },
        clubContact: {
          required: function(value) {
            return this.formData.gameMode.id === 'clubs' ? required(value) : true
          },
        },
      },
      console: {
        id: { required },
        customName: {
          required: function(value) {
            // This ensures the validation is properly triggered for 'other' console
            return this.formData.console.id === 'other' ? !!value && value.trim().length > 0 : true
          },
        },
      },
      onlineSubscription: {
        hasSubscription: {
          required: function() {
            const selectedConsole = this.consoles.find(
              c => c.id === this.formData.console.id
            )
            return selectedConsole?.subscription ? required(this.formData.onlineSubscription.hasSubscription) : true
          },
        },
      },
      onlineId: {
        required: function() {
          return this.formData.onlineSubscription.hasSubscription ? required(this.formData.onlineId) : true
        },
      },
    },
  },

  computed: {
    ...mapState('multiStepForm', ['formData']),
    ...mapGetters('multiStepForm', ['CONSOLES']),

    consoles() {
      return this.CONSOLES
    },

    separatorStyle() {
      return {
        height: '1px',
        width: '100%',
      }
    },
  },

  methods: {
    ...mapMutations('multiStepForm', ['setValidationStatus']),

    touchField(fieldName) {
      this.$set(this.touchedFields, fieldName, true)
      this.validateFields()
    },

    showFieldError(fieldName) {
      return (this.touchedFields[fieldName] || this.formSubmitAttempted) &&
             this.$v.formData[fieldName].$error
    },

    validateFields: debounce(function() {
      if (!this.isInitialized) return

      this.$v.$touch()
      const isValid = !this.$v.$invalid && this.validateRequiredFields()
      this.setValidationStatus(!!isValid)
    }, 300),

    validateRequiredFields() {
      // Basic required fields
      if (!this.formData.videoGame || !this.formData.gameMode.id || !this.formData.console.id) {
        return false
      }

      // Validate clubs mode fields
      if (this.formData.gameMode.id === 'clubs') {
        if (!this.formData.gameMode.clubName || !this.formData.gameMode.clubContact) {
          return false
        }
      }

      // Specific validation for custom console name
      if (this.formData.console.id === 'other') {
        const customName = this.formData.console.customName
        if (!customName || customName.trim().length === 0) {
          return false
        }
      }

      // Validate subscription and online ID
      const selectedConsole = this.consoles.find(c => c.id === this.formData.console.id)
      if (selectedConsole?.subscription) {
        if (this.formData.onlineSubscription.hasSubscription === null ||
            this.formData.onlineSubscription.hasSubscription === undefined) {
          return false
        }
        if (this.formData.onlineSubscription.hasSubscription && !this.formData.onlineId) {
          return false
        }
      }

      return true
    },

    initializeValidation() {
      this.isInitialized = true
      this.validateFields()
    },
  },

  created() {
    this.$nextTick(() => {
      this.initializeValidation()
    })
  },

  watch: {
    formData: {
      deep: true,
      handler() {
        if (this.isInitialized) {
          this.validateFields()
        }
      },
    },
    // Add specific watch for console changes
    'formData.console': {
      deep: true,
      handler(newValue) {
        if (this.isInitialized) {
          // Force validation when console changes
          this.$nextTick(() => {
            this.validateFields()
          })
        }
      },
    },
  },

  beforeDestroy() {
    if (this.validateFields.cancel) {
      this.validateFields.cancel()
    }
  },
}
</script>
