<template>
  <div class="w-full flex flex-col justify-start">
    <div class="flex flex-col md:grid grid-cols-8 gap-0 mx-6 md:mx-0">
      <div class="grid grid-cols-subgrid col-span-2">
        <StepsContainer />
      </div>
      <div class="grid grid-cols-subgrid col-span-4">
        <StepContainer />
      </div>
      <div class="grid grid-cols-subgrid col-span-2"></div>

      <NavigationBar />
    </div>
  </div>
</template>

<script>
import StepsContainer from './components/StepsContainer.vue'
import StepContainer from './components/StepContainer.vue'
import NavigationBar from './components/NavigationBar.vue'

import { mdiChevronRight, mdiChevronLeft } from '@mdi/js'

export default {
  name: 'MultiStepForm',
  components: {
    StepsContainer,
    StepContainer,
    NavigationBar,
  },
  data() {
    return {
      chevronRight: mdiChevronRight,
      chevronLeft: mdiChevronLeft,
    }
  },
}
</script>
