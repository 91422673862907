<template>
  <div class="h-screen bg-primary flex flex-col">
    <!-- ULEAGUE's Logo -->
    <div
      :class="[
        'flex justify-center w-full',
        onboardingStarted
          ? 'relative'
          : 'xs:absolute xs:top-10 md:absolute md:top-20 animate-pulse-in',
      ]"
    >
      <Logo :isWhite="true" svgClass="w-60 md:w-80"/>
    </div>

    <div class="flex-grow h-full flex justify-center">
      <div class="flex w-full h-full justify-center pt-0 max-w-screen-lg">
        <Welcome
          v-if="!onboardingStarted"
          :onEnterGame="startOnboarding"
        />
        <Onboarding v-else />
      </div>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/Logo/Logo.vue'
import Welcome from '@/views/Welcome/Welcome.vue'
import Onboarding from '@/views/Onboarding/Onboarding.vue'

export default {
  name: 'WelcomeContainerPage',
  components: {
    Logo,
    Welcome,
    Onboarding,
  },
  data() {
    return {
      onboardingStarted: false,
    }
  },
  methods: {
    startOnboarding() {
      this.onboardingStarted = true
    },
  },
  metaInfo() {
    return {
      title: this.$t('welcome.welcome'),
      meta: [{ name: 'description', content: this.$t('appDescription') }],
    }
  },
}
</script>
