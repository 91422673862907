<template>
  <div class="w-full md:h-full pb-4 md:pb-0 md:pr-6">
    <div class="flex md:flex-col py-0 justify-center">
      <StepTitle
        v-for="(step, index) in steps"
        :key="step.id"
        :step="step"
        :isActive="currentStep >= step.id"
        :showProgressStep="index !== 0"
        @click="setStep(step.id)"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import StepTitle from './StepTitle.vue'

export default {
  components: {
    StepTitle,
  },
  computed: {
    ...mapState('multiStepForm', ['currentStep', 'steps']),
  },
  methods: {
    ...mapActions('multiStepForm', ['setStep']),
  },
}
</script>
