const colors = {
  primary: {
    DEFAULT: '#011526',
    light: '#162B40',
  },
  secondary: '#A7CBD9',
  tertiary: '#73A2BF',
  accent: '#304B98',
  white: '#FFF',
  black: '#000',
  greenFC: '#06f366',
  purpleDiscord: '#5865F2',
}

module.exports = { colors }
