<template>
  <div class="mb-8">
    <p class="text-white text-sm mb-4">{{ title }}</p>
    <div class="bg-tertiary bg-opacity-25 h-px"></div>
  </div>
</template>

<script>
export default {
  name: 'FormHeader',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
}
</script>
