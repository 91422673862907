<template>
  <a :href="route" @click.prevent="handleClick">
    <inline-svg
      :src="getLogoSrc"
      :title="$t('welcome.logo')"
      :class="svgClass"
    />
  </a>
</template>

<script>
import InlineSvg from 'vue-inline-svg'

export default {
  components: {
    InlineSvg,
  },
  props: {
    isWhite: {
      type: Boolean,
      default: false, // Default to the carbon version
    },
    svgClass: {
      type: String,
      default: 'w-60', // Default class for the inline-svg component
    },
    route: {
      type: String,
      default: '/',
    },
  },
  computed: {
    getLogoSrc() {
      return this.isWhite
        ? require('@/assets/logos/logo-white.svg')
        : require('@/assets/logos/logo-carbon.svg')
    },
  },
}
</script>
