<template>
  <div class="bg-primary flex flex-col md:justify-center min-h-full w-full">
    <div class="mx-auto">
      <div class="flex flex-col md:flex-row items-start justify-between md:p-4">
        <!-- Left side - Video -->
        <div class="w-full lg:w-2/5 mb-8 lg:mb-0 self-center">
          <LocalizedVideo :enVideo="enVideo" :esVideo="esVideo" class="m-auto" />
        </div>

        <!-- Right side - Welcome text -->
        <div class="w-full lg:w-3/5 lg:pl-20 self-center px-6 space-y-4">
          <h1 class="text-2xl lg:text-4xl font-bold text-white">
            {{ $t('welcome.welcomeToUleague') }}
          </h1>
          <p class="text-md lg:text-md text-gray-200 text-justify leading-6" v-html="$t('welcome.uleagueDescription')"></p>
          <button
            @click="handleEnterGame"
            class="relative bg-accent hover:bg-white text-white hover:text-primary font-bold py-4 px-6 rounded-lg uppercase w-full lg:max-w-md transition duration-300 flex items-center justify-center"
          >
            {{ $t('welcome.enterGameNow') }}

            <!-- Logos -->
            <div class="flex justify-end items-center space-x-1 mt-4 absolute left-0 right-0 -bottom-10">
              <div class="w-10 h-10 flex justify-center items-center">
                <inline-svg
                  :src="require('@/assets/logos/games/eafc.svg')"
                  title="EAFC Logo"
                  class="h-6 w-8 svg-white"
                ></inline-svg>
              </div>
              <div class="w-10 h-10 flex justify-center items-center relative">
                <inline-svg
                  :src="require('@/assets/logos/games/eFootball.svg')"
                  title="eFootball Logo"
                  class="h-8 svg-white absolute top-0 bottom-0 left-0 right-0 m-auto"
                ></inline-svg>
              </div>
              <div class="w-10 h-10 flex justify-center items-center p-2">
                <inline-svg
                  :src="require('@/assets/logos/consoles/ps.svg')"
                  title="PlayStation Logo"
                  class="platform-logo w-8 svg-white"
                ></inline-svg>
              </div>
              <div class="w-10 h-10 flex justify-center items-center p-2">
                <inline-svg
                  :src="require('@/assets/logos/consoles/xbox.svg')"
                  title="Xbox Logo"
                  class="platform-logo svg-white"
                ></inline-svg>
              </div>
              <div class="w-10 h-10 flex justify-center items-center p-2">
                <inline-svg
                  :src="require('@/assets/logos/consoles/windows.svg')"
                  title="Windows Logo"
                  class="platform-logo svg-white"
                ></inline-svg>
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LocalizedVideo from '@/components/LocalizedVideo/LocalizedVideo.vue'
import InlineSvg from 'vue-inline-svg'

export default {
  name: 'WelcomePage',
  components: {
    LocalizedVideo,
    InlineSvg,
  },
  props: {
    onEnterGame: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      enVideo: {
        src: 'https://play.vidyard.com/4J8pEdZtkaLN3bBxqFBbNZ.jpg',
        uuid: '4J8pEdZtkaLN3bBxqFBbNZ',
      },
      esVideo: {
        src: 'https://play.vidyard.com/cXq7eQZPqgtqXwcXHt3gzh.jpg',
        uuid: 'cXq7eQZPqgtqXwcXHt3gzh',
      },
    }
  },
  methods: {
    handleEnterGame() {
      this.onEnterGame()
    },
  },
  metaInfo() {
    return {
      title: this.$t('welcome.welcome'),
      meta: [{ name: 'description', content: this.$t('appDescription') }],
    }
  },
}
</script>

<style scoped>
.platform-logo {
  width: 42px;
  height: 42px;
}
</style>
