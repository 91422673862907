<template>
  <div
    @click="$emit('click')"
    :class="[
      containerClass,
      'transition-all duration-300 ease-in-out'
    ]"
    :style="containerStyle"
  >
    <div class="pt-3 md:pt-0 hidden md:block">
      <h2 class="font-thin text-sm text-right uppercase leading-3 md:pr-4">{{ $t(step.titleI18n) }}</h2>
    </div>

    <div class="flex items-center justify-center" :style="iconContainerStyle">
      <div :class="iconContainerClass" :style="iconSizeStyle">
        <!-- Show this icon if step.icon is 'discord' -->
        <div v-if="step.icon === 'discord'" class="flex justify-center items-center">
          <inline-svg
            :src="require('@/assets/logos/discord-white.svg')"
            title="DISCORD Logo"
            class="discord-logo p-1"
          ></inline-svg>
        </div>

        <!-- else Show this icon if step.icon is 'download' -->
        <div v-else-if="step.icon === 'download'" class="flex justify-center items-center">
          <inline-svg
            :src="require('@/assets/logos/uleague-logo-white.svg')"
            title="ULEAGUE Logo"
            class="app-logo logo-white"
          ></inline-svg>
        </div>

        <!-- else -->
        <Icon v-else :path="iconPath" :size="24" :color="iconColor" />

        <!-- Conditionally render the progress step based on showProgressStep prop -->
        <div
          v-if="showProgressStep"
          class="flex items-center absolute bg-secondary bg-opacity-50"
          :style="distanceStyle"
        >
          <div
            class="hidden md:block absolute top-0 bottom-0 w-full bg-greenFC transition-all duration-300"
            :style="{ height: `${progress}%` }"
          />
          <div
            class="block md:hidden absolute top-0 bottom-0 w-full bg-greenFC transition-all duration-300"
            :style="{ width: `${progress}%` }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mdiAccount, mdiFlagCheckered, mdiControllerClassicOutline, mdiSquareOutline } from '@mdi/js'
import Icon from '@/components/Icon/Icon.vue'
import InlineSvg from 'vue-inline-svg'
import { colors } from '@/shared/theme'
import _ from 'lodash'

export default {
  name: 'StepComponent',
  components: { Icon, InlineSvg },

  data() {
    return {
      stepHeight: 80,
      stepWidth: 120,
      stepIconSize: 30,
      progress: 0,
      visualState: 'inactive',
      progressInterval: null,
      isMobileLayout: false,
    }
  },

  props: {
    step: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    showProgressStep: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    containerClass() {
      return [
        'flex flex-col items-center justify-end cursor-pointer box-border',
        'pt-3 pb-2 md:py-0 flex-col-reverse md:flex-row',
        this.visualState === 'active' ? 'text-white' : 'text-gray-400',
      ]
    },

    iconContainerClass() {
      return {
        'flex rounded-full items-center justify-center relative': true,
        'bg-secondary bg-opacity-50': this.visualState === 'inactive',
        'bg-greenFC': this.visualState === 'active',
      }
    },

    iconContainerStyle() {
      return {
        marginTop: '0px',
        transition: 'all 0.3s ease-in-out',
      }
    },

    iconSizeStyle() {
      return {
        width: `${this.stepIconSize}px`,
        height: `${this.stepIconSize}px`,
        transition: 'all 0.3s ease-in-out',
      }
    },

    containerStyle() {
      return {
        ...(this.isMobileLayout
          ? { width: `${this.stepWidth / 2}px` }
          : { height: `${this.stepHeight}px` }),
        transition: 'all 0.3s ease-in-out',
      }
    },

    distanceStyle() {
      const base = {
        transition: 'all 0.3s ease-in-out',
        position: 'absolute',
      }

      const distance = this.calculateDistanceBetweenSteps()

      return this.isMobileLayout ? {
        ...base,
        width: `${distance}px`,
        left: `-${distance}px`,
        height: '1px',
      } : {
        ...base,
        height: `${distance}px`,
        top: `-${distance}px`,
        width: '1px',
      }
    },

    iconPath() {
      const icons = {
        user: mdiAccount,
        done: mdiFlagCheckered,
        gamepad: mdiControllerClassicOutline,
        trophy: mdiSquareOutline,
      }
      return icons[this.step.icon] || mdiAccount
    },

    iconColor() {
      return colors.white
    },
  },

  created() {
    // Debounce the resize handler
    this.debouncedResize = _.debounce(this.handleResize, 250)
  },

  mounted() {
    // Initial layout check
    this.checkLayout()
    // Add resize listener
    window.addEventListener('resize', this.debouncedResize)
  },

  methods: {
    checkLayout() {
      this.isMobileLayout = typeof window !== 'undefined' ? window.innerWidth < 768 : false
      this.updateLayout()
    },

    handleResize() {
      this.checkLayout()
    },

    updateLayout() {
      this.$nextTick()
    },

    calculateDistanceBetweenSteps() {
      return this.isMobileLayout
        ? this.stepIconSize
        : this.stepHeight - this.stepIconSize
    },

    startProgressAnimation(isIncreasing) {
      // Wait for layout to stabilize
      this.$nextTick(() => {
        if (this.progressInterval) clearInterval(this.progressInterval)

        this.progress = isIncreasing ? 0 : 100
        const duration = 300
        const steps = duration / (1000 / 60)
        const incrementPerStep = (isIncreasing ? 100 : -100) / steps
        let currentStep = 0

        this.progressInterval = setInterval(() => {
          currentStep++
          if (isIncreasing) {
            if (currentStep >= steps) {
              this.progress = 100
              setTimeout(() => {
                this.visualState = 'active'
              }, 200)
              clearInterval(this.progressInterval)
            } else {
              this.progress = Math.min(currentStep * incrementPerStep, 100)
            }
          } else {
            if (currentStep >= steps || this.progress <= 0) {
              this.progress = 0
              clearInterval(this.progressInterval)
            } else {
              this.progress = Math.max(100 - currentStep * Math.abs(incrementPerStep), 0)
            }
          }
        }, 1000 / 60)
      })
    },
  },

  watch: {
    isActive: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.startProgressAnimation(true)
        } else {
          this.visualState = 'inactive'
          this.startProgressAnimation(false)
        }
      },
    },
  },

  beforeDestroy() {
    // Clean up all listeners and intervals
    window.removeEventListener('resize', this.debouncedResize)
    if (this.progressInterval) clearInterval(this.progressInterval)
  },
}
</script>

<style scoped>
.discord-logo {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.app-logo {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.logo-white {
  color: white !important;
}
</style>
