<template>
  <div class="space-y-4">
    <div class="mb-8">
      <p class="text-white text-sm mb-4">{{ $t("personalInfoStep.title") }}</p>
      <div class="bg-tertiary bg-opacity-25" :style="separatorStyle"></div>
    </div>
    <div class="space-y-4">
      <div>
        <label for="full-name" class="block text-sm font-medium mb-1 text-secondary">{{ $t("personalInfoStep.name.label") }}</label>
        <input
          id="full-name"
          v-model.trim="$v.name.$model"
          type="text"
          inputmode="text"
          @input="validateFields"
          @blur="touchField('name')"
          ref="nameField"
          :class="[
            'w-full p-2 rounded bg-primary text-white text-xl',
            { 'border border-red-500': showFieldError('name') }
          ]"
        />
        <div class="mt-1 text-sm text-red-500" v-if="showFieldError('name')">
          <span v-if="!$v.name.required">{{ $t("personalInfoStep.name.error.required") }}</span>
          <span v-else-if="!$v.name.minLength">{{ $t("personalInfoStep.name.error.minLength") }}</span>
          <span v-else-if="!$v.name.maxLength">{{ $t("personalInfoStep.name.error.maxLength") }}</span>
        </div>
      </div>

      <div>
        <label for="email" class="block text-sm font-medium mb-1 text-secondary">{{ $t("personalInfoStep.email.label") }}</label>
        <input
          id="email"
          v-model.trim="$v.email.$model"
          type="email"
          inputmode="email"
          @input="validateFields"
          @blur="validateEmail"
          :disabled="isEmailLocked"
          :class="[
            'w-full p-2 rounded bg-primary text-white text-xl',
            { 'border border-red-500': showFieldError('email') || emailInUse },
            { 'opacity-75 cursor-not-allowed': isEmailLocked }
          ]"
        />
        <div class="mt-1 text-sm text-red-500" v-if="showFieldError('email') || emailInUse">
          <span v-if="!$v.email.required">{{ $t("personalInfoStep.email.error.required") }}</span>
          <span v-else-if="!$v.email.email">{{ $t("personalInfoStep.email.error.format") }}</span>
          <span v-else-if="emailInUse">{{ $t("personalInfoStep.email.error.inUse") }}</span>
        </div>
      </div>

      <div>
        <label for="country" class="block text-sm font-medium mb-1 text-secondary">{{ $t("personalInfoStep.country.label") }}</label>
        <v-select
          id="country"
          v-model="selectedCountry"
          :options="countries"
          label="label"
          :placeholder="$t('personalInfoStep.country.placeholder')"
          @input="handleCountryChange"
          @blur="touchField('selectedCountry')"
          :class="['style-chooser text-white', { 'error-select': showFieldError('selectedCountry') }]"
        />
        <div class="mt-1 text-sm text-red-500" v-if="showFieldError('selectedCountry')">
          <span>{{ $t("personalInfoStep.country.error.required") }}</span>
        </div>
      </div>

      <div>
        <label for="whatsapp" class="block text-sm font-medium mb-1 text-secondary">{{ $t("personalInfoStep.whatsapp.label") }}</label>
        <input
          id="whatsapp"
          v-model="whatsapp"
          type="tel"
          inputmode="numeric"
          pattern="[0-9]*"
          @input="handleWhatsAppInput"
          @blur="touchField('whatsapp')"
          :placeholder="$t('personalInfoStep.whatsapp.placeholder')"
          ref="whatsappField"
          :class="[
            'w-full p-2 rounded bg-primary text-white text-xl',
            { 'border border-red-500': showFieldError('whatsapp') }
          ]"
        />
        <div class="mt-1 text-sm text-red-500" v-if="showFieldError('whatsapp')">
          <span v-if="!$v.whatsapp.required">{{ $t("personalInfoStep.whatsapp.error.required") }}</span>
          <span v-else-if="!$v.whatsapp.phoneNumber">{{ $t("personalInfoStep.whatsapp.error.format") }}</span>
        </div>
      </div>

      <!-- Terms and Conditions -->
      <div>
        <div class="flex items-center space-x-2">
          <input
            type="checkbox"
            id="acceptTerms"
            v-model="acceptTerms"
            class="h-4 w-4 rounded border-gray-300"
            @change="handleTermsChange"
          />
          <label for="acceptTerms" class="text-sm text-secondary">
            {{ $t('personalInfoStep.terms.label') }}
            <a
              href="/terms"
              target="_blank"
              class="text-accent hover:text-white underline"
              @click.stop
            >
              {{ $t('personalInfoStep.terms.link') }}
            </a>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import countryList from 'country-list-with-dial-code-and-flag'
import { required, minLength, maxLength, email, sameAs } from 'vuelidate/lib/validators'
import { parsePhoneNumberFromString, isValidPhoneNumber } from 'libphonenumber-js'
import debounce from 'lodash/debounce'

export default {
  name: 'PersonalInfoStep',

  validations: {
    name: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(50),
    },
    email: {
      required,
      email,
    },
    selectedCountry: {
      required,
    },
    whatsapp: {
      required,
      phoneNumber(value) {
        if (!value) return true
        const phoneNumber = this.formatPhoneNumber(value)
        return isValidPhoneNumber(phoneNumber)
      },
    },
    acceptTerms: {
      required,
      sameAs: sameAs(() => true),
    },
  },

  data() {
    return {
      countries: [],
      previousCountry: null,
      acceptTerms: false,
      touchedFields: {
        name: false,
        email: false,
        selectedCountry: false,
        whatsapp: false,
        acceptTerms: false,
      },
      formSubmitAttempted: false,
      isInitialized: false,
    }
  },

  computed: {
    ...mapState('multiStepForm', {
      formData: state => state.formData,
      emailInUse: state => state.emailInUse,
      initialEmail: state => state.initialEmail,
    }),

    name: {
      get() {
        return this.formData.name
      },
      set(value) {
        this.updateFormData({ field: 'name', value })
      },
    },

    email: {
      get() {
        return this.formData.email
      },
      set(value) {
        this.updateFormData({ field: 'email', value })
      },
    },

    whatsapp: {
      get() {
        return this.formData.whatsapp || ''
      },
      set(value) {
        this.updateFormData({ field: 'whatsapp', value })
      },
    },

    selectedCountry: {
      get() {
        return this.formData.country
      },
      set(value) {
        this.previousCountry = this.formData.country
        this.updateFormData({ field: 'country', value })
      },
    },

    phonePlaceholder() {
      return this.selectedCountry ? `${this.selectedCountry.dial_code}` : ''
    },

    separatorStyle() {
      return { height: '1px' }
    },

    isEmailLocked() {
      return !!this.initialEmail
    },

    acceptedTerms: {
      get() {
        return this.formData.acceptedTerms
      },
      set(value) {
        this.updateFormData({ field: 'acceptedTerms', value })
      },
    },
  },

  created() {
    this.$nextTick(() => {
      this.initializeForm()
      this.focusNameField()
    })
  },

  methods: {
    ...mapActions('multiStepForm', ['updateFormData', 'validateEmail']),
    ...mapMutations('multiStepForm', ['setValidationStatus']),

    initializeForm() {
      const invitedBy = this.$route.query.invited
      if (invitedBy) {
        this.updateFormData({
          field: 'invitedBy',
          value: invitedBy,
        })
      }

      this.countries = countryList.getAll().map((country) => ({
        label: `${country.flag} ${country.name} (${country.dial_code})`,
        name: country.name,
        dial_code: country.dial_code,
        code: country.code,
        flag: country.flag,
      }))

      if (this.formData.country) {
        this.selectedCountry = this.countries.find(
          c => c.dial_code === this.formData.country.dial_code
        )
      }

      this.acceptTerms = this.formData.acceptedTerms

      this.isInitialized = true
      this.validateFields()
    },

    touchField(fieldName) {
      this.$set(this.touchedFields, fieldName, true)
      this.$v[fieldName].$touch()
      this.validateFields()
    },

    showFieldError(fieldName) {
      return (this.touchedFields[fieldName] || this.formSubmitAttempted) &&
             (fieldName === 'acceptTerms' ? !this.acceptTerms : this.$v[fieldName].$error)
    },

    validateFields: debounce(function() {
      if (!this.isInitialized) return

      this.$v.$touch()
      const isValid = !this.$v.$invalid &&
                     this.name &&
                     this.email &&
                     this.selectedCountry &&
                     this.whatsapp &&
                     this.formData.acceptedTerms &&
                     this.emailInUse === false

      this.setValidationStatus(!!isValid)
    }, 300),

    validateEmail() {
      if (this.isEmailLocked) return

      try {
        this.$store.dispatch('multiStepForm/validateEmail')
        this.validateFields()
      } catch (error) {
        console.error('Email validation failed:', error)
      }
    },

    formatPhoneNumber(number) {
      if (!number) return ''

      if (number.startsWith('+')) {
        return number
      }

      if (this.selectedCountry && this.selectedCountry.dial_code) {
        return `${this.selectedCountry.dial_code}${number}`
      }

      return number
    },

    validatePhoneNumber() {
      const formattedNumber = this.formatPhoneNumber(this.whatsapp)
      try {
        const phoneNumber = parsePhoneNumberFromString(formattedNumber)
        return phoneNumber ? phoneNumber.isValid() : false
      } catch {
        return false
      }
    },

    handleCountryChange(newCountry) {
      if (!this.whatsapp || this.previousCountry !== newCountry) {
        this.$nextTick(() => this.$refs.whatsappField.focus())
      }
      this.selectedCountry = newCountry
    },

    handleWhatsAppInput(event) {
      event.target.value = event.target.value.replace(/\D/g, '')
      this.whatsapp = event.target.value
      this.validateFields()
    },

    focusNameField() {
      this.$nextTick(() => {
        this.$refs.nameField.focus()
      })
    },

    handleTermsChange(event) {
      this.touchField('acceptTerms')
      this.acceptTerms = event.target.checked
      this.updateFormData({
        field: 'acceptedTerms',
        value: event.target.checked,
      })
      this.validateFields()
    },
  },

  watch: {
    '$v.$invalid': {
      handler(newValue) {
        if (this.isInitialized) {
          this.validateFields()
        }
      },
      immediate: true,
    },

    formData: {
      handler() {
        if (this.isInitialized) {
          this.validateFields()
        }
      },
      deep: true,
    },

    selectedCountry: {
      handler(newValue) {
        this.updateFormData({ field: 'country', value: newValue })
      },
    },

    email(newEmail, oldEmail) {
      if (newEmail !== oldEmail) {
        this.$store.dispatch('multiStepForm/resetEmailInUse')
        this.validateFields()
      }
    },

    'formData.acceptedTerms': {
      immediate: true,
      handler(newValue) {
        if (this.isInitialized) {
          this.acceptTerms = newValue
          this.validateFields()
        }
      },
    },
  },

  beforeDestroy() {
    if (this.validateFields.cancel) {
      this.validateFields.cancel()
    }
  },
}
</script>

<style>
/* Style adjustments */
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu,
.style-chooser .vs__selected {
  background: #011526;
  color: white;
  font-variant: small-caps;
  padding-top: 4px;
  padding-bottom: 5px;
}

.error-select .vs__dropdown-toggle {
  border-color: rgb(239, 68, 68) !important;
}

.style-chooser ul li {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

/* Add these styles */
input:disabled {
  opacity: 0.75;
  cursor: not-allowed;
}
</style>
