<template>
  <div class="flex flex-col h-full w-full">
    <div class="h-screen flex flex-col">
      <OnboardingHeader />
      <MultiStepForm />
    </div>
  </div>
</template>

<script>

import OnboardingHeader from '@/components/OnboardingHeader/OnboardingHeader.vue'
import MultiStepForm from '@/components/MultiStepForm/MultiStepForm.vue'

export default {
  name: 'OnboardingPage',
  components: {
    OnboardingHeader,
    MultiStepForm,
  },
  data() {
    return {}
  },
}
</script>
