<template>
  <div class="space-y-6">
    <!-- Welcome Message Section -->
    <div class="mb-8">
      <h2 class="text-lg font-semibold text-white mb-4">{{ $t('discordInviteStep.welcome.title') }}</h2>

      <!-- Logo -->
      <div class="flex justify-center mb-4">
        <inline-svg
          :src="require('@/assets/logos/discord-white.svg')"
          title="DISCORD Logo"
          class="discord-logo bg-purpleDiscord p-5"
        ></inline-svg>
      </div>

      <div class="bg-secondary/10 text-white rounded-lg space-y-6">
        <!-- Main Welcome Message -->
        <p class="text-sm leading-relaxed" v-html="$t('discordInviteStep.welcome.message')"></p>

        <!-- Highlights -->
        <div class="space-y-2">
          <h3 class="font-medium text-sm">{{ $t('discordInviteStep.welcome.highlights.title') }}</h3>
          <ul class="space-y-3">
            <li class="flex items-center text-sm">
              <span>{{ $t('discordInviteStep.welcome.highlights.tournaments') }}</span>
            </li>
            <li class="flex items-center text-sm">
              <span>{{ $t('discordInviteStep.welcome.highlights.prizes') }}</span>
            </li>
            <li class="flex items-center text-sm">
              <span>{{ $t('discordInviteStep.welcome.highlights.community') }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Discord Username -->
    <div class="space-y-2">
      <label for="discord-username" class="block text-sm font-medium text-secondary">
        {{ $t('discordInviteStep.discord.label') }}
      </label>
      <input
        id="discord-username"
        v-model="discordUsername"
        type="text"
        ref="discordUsername"
        :placeholder="$t('discordInviteStep.discord.placeholder')"
        @blur="validateDiscordUsername"
        :class="[
          'w-full p-2 rounded bg-primary text-white text-xl',
          { 'border border-red-500': showDiscordError }
        ]"
      />
      <div class="mt-1 text-sm text-red-500" v-if="showDiscordError">
        <span v-if="!discordUsername">{{ $t('discordInviteStep.discord.error.required') }}</span>
        <span v-else>{{ $t('discordInviteStep.discord.error.format') }}</span>
      </div>
    </div>

    <!-- Streaming Section -->
    <div class="space-y-4">
      <label for="streaming-status" class="block text-sm font-medium text-secondary">
        {{ $t('discordInviteStep.stream.label') }}
      </label>
      <div class="grid grid-cols-2 gap-4">
        <OptionCard
          title="Yes"
          color="green"
          :isSelected="isStreamer"
          @onClick="setStreamerStatus(true)"
          size="sm"
        />
        <OptionCard
          title="No"
          color="red"
          :isSelected="!isStreamer"
          @onClick="setStreamerStatus(false)"
          size="sm"
        />
      </div>

      <div v-if="isStreamer" class="space-y-2">
        <label for="channel-url" class="block text-sm font-medium text-secondary">
          {{ $t('discordInviteStep.stream.channel.label') }}
        </label>
        <input
          id="channel-url"
          v-model="channelUrl"
          type="url"
          ref="channelUrl"
          :placeholder="$t('discordInviteStep.stream.channel.placeholder')"
          @blur="validateChannelUrl"
          :class="[
            'w-full p-2 rounded bg-primary text-white text-xl',
            { 'border border-red-500': showChannelError }
          ]"
        />
        <div class="mt-1 text-sm text-red-500" v-if="showChannelError">
          <span v-if="!channelUrl">{{ $t('discordInviteStep.stream.channel.error.required') }}</span>
          <span v-else>{{ $t('discordInviteStep.stream.channel.error.format') }}</span>
        </div>
      </div>
    </div>

    <!-- Accept Invitation QR Code or Button -->
    <div class="flex flex-col">
      <div class="hidden md:block">
        <p class="text-white text-xs text-center pb-4 px-10 max-w-xs m-auto">
          {{ $t('discordInviteStep.qrScanMessage') }}
        </p>
        <div class="m-auto flex justify-center items-center">
          <canvas ref="qrCanvas"></canvas>
        </div>
      </div>
      <div class="flex justify-center w-full md:hidden">
        <button
          @click="acceptInvitation"
          style="background-color: #5865f2"
          class="relative bg-accent hover:bg-white text-white hover:text-primary font-bold py-4 px-6 rounded-lg uppercase w-full lg:max-w-md transition duration-300 flex items-center justify-center"
        >
          {{ $t('discordInviteStep.acceptInvite') }}
        </button>
      </div>
    </div>

    <!-- already joined discord -->
    <div>
      <div class="flex items-center space-x-2">
        <input
          type="checkbox"
          id="alreadyJoinedDiscord"
          v-model="alreadyJoinedDiscord"
          class="h-4 w-4 rounded border-gray-300"
        />
        <label for="alreadyJoinedDiscord" class="text-sm text-secondary">
          {{ $t('discordInviteStep.alreadyJoinedDiscord.label') }}
        </label>
      </div>
      <div class="mt-1 text-sm text-red-500 pt-2" v-if="showAlreadyJoinedDiscordError">
        {{ $t('discordInviteStep.alreadyJoinedDiscord.error') }}
      </div>
    </div>

    <div
      v-if="showModal" class="fixed inset-0 z-50 flex items-center justify-center bg-white bg-opacity-40"
      style="margin-top: 0px;"
    >
      <!-- Backdrop -->
      <div class="absolute inset-0 bg-black/50" @click="showModal = false"></div>

      <!-- Modal -->
      <div
        class="relative bg-primary p-6 rounded-lg w-full max-w-md mx-4 space-y-4"
      >
        <div class="space-y-2">
          <h3 class="text-lg font-medium text-white">
            {{ $t('discordInviteStep.modal.title') }}
          </h3>
          <p class="text-sm text-secondary">
            {{ $t('discordInviteStep.modal.description') }}
          </p>
        </div>

        <div class="grid grid-cols-2 space-x-4">
          <button
            style="background-color: #5865f2"
            class="py-2 px-4 text-white rounded-md font-medium shadow-lg transition-all w-full"
            @click="openDiscordInvite"
          >
            {{ $t('discordInviteStep.modal.action') }}
          </button>
          <button
            class="flex-1 px-4 py-2 bg-gray-600 text-white rounded-lg hover:opacity-90 transition-colors w-full"
            @click="showModal = false"
          >
            {{ $t('discordInviteStep.modal.cancel') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import OptionCard from '../components/OptionCard.vue'
import InlineSvg from 'vue-inline-svg'
import QRCode from 'qrcode'

export default {
  name: 'DiscordInviteStep',

  components: {
    OptionCard,
    InlineSvg,
  },

  data() {
    return {
      discordTouched: false,
      channelTouched: false,
      alreadyJoinedDiscordTouched: false,
      showModal: false,
      DISCORD_INVITE_URL: 'https://discord.gg/ECNkJRa3Td',
    }
  },

  computed: {
    ...mapState('multiStepForm', {
      formData: state => state.formData,
    }),

    discordUsername: {
      get() {
        return this.formData.discordUsername
      },
      set(value) {
        this.updateFormData({ field: 'discordUsername', value })
      },
    },

    isStreamer: {
      get() {
        // Ensure we return a boolean even if value is undefined
        return !!this.formData.isStreamer
      },
      set(value) {
        this.updateFormData({ field: 'isStreamer', value })
      },
    },

    channelUrl: {
      get() {
        return this.formData.broadcastLink || ''
      },
      set(value) {
        this.updateFormData({ field: 'broadcastLink', value })
      },
    },

    alreadyJoinedDiscord: {
      get() {
        return this.formData.alreadyJoinedDiscord || false
      },
      set(value) {
        this.updateFormData({ field: 'alreadyJoinedDiscord', value })
      },
    },

    isDiscordValid() {
      const username = this.discordUsername.trim()
      return username.length >= 3 && !username.includes(' ')
    },

    isChannelUrlValid() {
      if (!this.isStreamer) return true

      const url = this.channelUrl.trim()
      if (!url) return false

      return url.includes('/') && !url.includes(' ')
    },

    showDiscordError() {
      return this.discordTouched && !this.isDiscordValid
    },

    showChannelError() {
      return this.isStreamer && this.channelTouched && !this.isChannelUrlValid
    },

    showAlreadyJoinedDiscordError() {
      return this.alreadyJoinedDiscordTouched && !this.alreadyJoinedDiscord
    },
  },

  mounted() {
    this.generateQRCode()

    this.$nextTick(() => {
      if (!this.discordUsername) {
        if (this.$refs.discordUsername) {
          this.$refs.discordUsername.focus()
        }
      }
      // Validate step on mount to ensure the continue button is correctly enabled/disabled
      this.validateStep()
    })
  },

  methods: {
    ...mapActions('multiStepForm', ['updateFormData']),
    ...mapMutations('multiStepForm', ['setValidationStatus']),

    setStreamerStatus(status) {
      // Update store directly
      this.updateFormData({ field: 'isStreamer', value: status })

      if (!status) {
        // Clear broadcast link if streaming is disabled
        this.updateFormData({ field: 'broadcastLink', value: '' })
        this.channelTouched = false
      } else {
        this.$nextTick(() => {
          if (this.$refs.channelUrl) {
            this.$refs.channelUrl.focus()
          }
        })
      }
      this.validateStep()
    },

    validateDiscordUsername() {
      this.discordTouched = true
      this.validateStep()
    },

    validateChannelUrl() {
      this.channelTouched = true
      this.validateStep()
    },

    acceptInvitation() {
      this.showModal = true
    },

    generateQRCode() {
      const canvas = this.$refs.qrCanvas
      QRCode.toCanvas(canvas, this.DISCORD_INVITE_URL, {
        width: 160,
        margin: 2,
        color: {
          dark: '#5865f2',
          light: '#000000',
        },
      })
    },

    openDiscordInvite() {
      // Save current state first
      this.invitationAccepted = true
      this.validateStep()

      // Open Discord in new tab
      window.open(this.DISCORD_INVITE_URL, '_blank')

      // Close modal
      this.showModal = false
    },

    validateStep() {
      const isValid = this.isDiscordValid &&
                     this.isChannelUrlValid &&
                     this.alreadyJoinedDiscord

      this.setValidationStatus(isValid)
    },
  },

  watch: {
    'formData.isStreamer'(newValue) {
      // Ensure validation is updated when streaming status changes
      this.validateStep()
    },

    alreadyJoinedDiscord(newValue) {
      this.alreadyJoinedDiscordTouched = true
      this.validateStep()
    },

    formData: {
      handler() {
        this.validateStep()
      },
      deep: true,
    },
  },
}
</script>

<style scoped>
button[disabled] {
  opacity: 0.7;
}

.list-disc {
  list-style-type: disc;
}

/* Add styles for markdown rendering */
:deep(strong) {
  font-weight: 600;
}

:deep(em) {
  font-style: italic;
}

.fixed {
  position: fixed;
}

.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.z-50 {
  z-index: 50;
}

.discord-logo {
  width: 100px;
  height: 100px;
  border-radius: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
</style>
