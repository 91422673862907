<template>
  <div class="grid col-start-3 col-span-4 py-6">
    <div class="flex justify-between">
      <!-- Back Button (only shows when not on the first step) -->
      <div>
        <button
          @click="handlePrevStep"
          v-if="currentStep > 1"
          class="flex items-center bg-transparent py-3 pl-3 pr-6 rounded-lg text-sm text-white gap-0 w-min"
        >
          <Icon :path="chevronLeft" :size="24" :color="iconColor" />
          <span class="text-sm font-normal">
            {{ $t('navigationBar.back') }}
          </span>
        </button>
      </div>

      <!-- Continue/Finish Button (disabled if current step is invalid, except for the last step) -->
      <div v-if="!showFinishMessage">
        <button
          :disabled="!isStepValid && !isLastStep"
          @click="handleContinue"
          :class="['flex items-center py-3 pl-6 pr-4 rounded-lg text-sm gap-0 w-min', buttonClass]"
        >
          {{ isLastStep ? $t('navigationBar.finish') : $t('navigationBar.continue') }}
          <Icon :path="chevronRight" :size="24" :color="iconColor" />
        </button>
      </div>

      <!-- Finish Message -->
      <div v-else class="flex items-center">
        <span class="text-greenFC text-xl font-bold">
          {{ $t('thankYouStep.modalTitle') }}!!
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import Icon from '@/components/Icon/Icon.vue'
import { mdiChevronRight, mdiChevronLeft } from '@mdi/js'
import { colors } from '@/shared/theme'
import confetti from 'canvas-confetti'

export default {
  name: 'NavigationContainer',
  components: {
    Icon,
  },
  data() {
    return {
      chevronRight: mdiChevronRight,
      chevronLeft: mdiChevronLeft,
      confettiColors: ['#011526', '#A7CBD9', '#06f366', '#5865F2'],
      confettiAnimationId: null,
    }
  },
  computed: {
    ...mapState('multiStepForm', ['currentStep']),
    ...mapGetters('multiStepForm', ['isStepValid', 'showFinishMessage']),

    isLastStep() {
      return this.currentStep === this.$store.state.multiStepForm.steps.length
    },
    iconColor() {
      return colors.white
    },
    buttonClass() {
      return this.isStepValid
        ? 'bg-greenFC text-white cursor-pointer'
        : 'bg-gray-400 text-gray-200 cursor-not-allowed'
    },
  },
  methods: {
    ...mapActions('multiStepForm', ['nextStep', 'prevStep', 'setFinishMessage']),

    startConfettiAnimation() {
      const end = Date.now() + (5 * 1000) // 5 seconds duration

      const frame = () => {
        // Left side confetti
        confetti({
          particleCount: 3,
          angle: 60,
          spread: 55,
          origin: { x: 0, y: 0.6 },
          colors: this.confettiColors,
          ticks: 300,
          gravity: 0.8,
          scalar: 1.2,
          drift: 0,
        })

        // Right side confetti
        confetti({
          particleCount: 3,
          angle: 120,
          spread: 55,
          origin: { x: 1, y: 0.6 },
          colors: this.confettiColors,
          ticks: 300,
          gravity: 0.8,
          scalar: 1.2,
          drift: 0,
        })

        if (Date.now() < end) {
          this.confettiAnimationId = requestAnimationFrame(frame)
        }
      }

      frame()
    },

    stopConfettiAnimation() {
      if (this.confettiAnimationId) {
        cancelAnimationFrame(this.confettiAnimationId)
        this.confettiAnimationId = null
      }
    },

    handleContinue() {
      if (this.isStepValid) {
        if (window.scrollY === 0) {
          this.proceedToNextStep()
        } else {
          window.scrollTo({ top: 0, behavior: 'smooth' })
          this.waitForScrollToTop(this.proceedToNextStep)
        }
      }
    },

    handlePrevStep() {
      this.setFinishMessage(false)
      if (window.scrollY === 0) {
        this.prevStep()
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        this.waitForScrollToTop(this.prevStep)
      }
    },

    waitForScrollToTop(callback) {
      const onScroll = () => {
        if (window.scrollY === 0) {
          window.removeEventListener('scroll', onScroll)
          callback()
        }
      }
      window.addEventListener('scroll', onScroll)
    },

    proceedToNextStep() {
      if (this.isLastStep) {
        this.setFinishMessage(true)
        this.startConfettiAnimation()
        setTimeout(() => {
          this.submitForm()
          this.stopConfettiAnimation()
        }, 5000)
      } else {
        this.nextStep()
      }
    },

    submitForm() {
      console.log('Form submitted successfully.')
    },
  },

  beforeDestroy() {
    this.stopConfettiAnimation()
  },
}
</script>

<style scoped>
button[disabled] {
  opacity: 0.7;
}
</style>
