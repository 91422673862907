<template>
  <div
    class="px-2 py-4 rounded-lg cursor-pointer transition-all duration-200 bg-primary flex items-center justify-center"
    :class="[
      isSelected ? 'border-2 border-greenFC' : 'border-2 border-transparent hover:border-secondary',
      containerClassName
    ]"
    @click="handleClick"
  >
    <div class="flex flex-col items-center space-y-2 w-full">
      <div
        :class="sizeClasses"
        v-if="logo"
      >
        <inline-svg
          :src="require(`@/assets/${logo}`)"
          :title="title"
          class="icon-svg w-full h-full"
          :class="[color && `svg-${color}`, isSelected && 'svg-selected']"
        />
      </div>

      <span
        v-if="title"
        class="text-sm text-center font-medium"
        :class="[
          isSelected ? 'text-greenFC' : 'text-white',
          titleClassName
        ]"
      >
        {{ title }}
      </span>
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'

export default {
  name: 'OptionCard',

  components: {
    InlineSvg,
  },

  props: {
    logo: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      default: null,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'white',
    },
    size: {
      type: String,
      default: 'md',
      validator: (value) => ['xs', 'sm', 'md', 'lg', 'xl'].includes(value),
    },
    containerClassName: {
      type: String,
      default: '',
    },
    titleClassName: {
      type: String,
      default: '',
    },
  },

  computed: {
    sizeClasses() {
      const sizes = {
        xs: 'w-6 h-6',
        sm: 'w-8 h-8',
        md: 'w-12 h-12',
        lg: 'w-16 h-16',
        xl: 'w-24 h-24',
      }
      return `flex items-center justify-center ${sizes[this.size]}`
    },
  },

  methods: {
    handleClick() {
      this.$emit('onClick')
    },
  },
}
</script>

<style>
.icon-svg {
  display: block;
}

.icon-svg svg {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
}

.icon-svg svg path {
  fill: currentColor;
}

.svg-white {
  color: white;
}

.svg-gray {
  color: #6B7280;
}

.svg-green {
  color: #10B981;
}

.svg-blue {
  color: #3B82F6;
}

.svg-selected {
  color: #10FC61;
}
</style>
