<template>
  <div class="space-y-4">
    <div>
      <label for="preferred-videogame" class="block text-sm font-medium mb-1 text-secondary">
        {{ $t('gamerInfoStep.videoGame.label') }}
      </label>
      <div class="grid grid-cols-2 gap-4">
        <OptionCard
          v-for="game in videoGames"
          :key="game.id"
          :title="game.name"
          :color="game.color || 'gray'"
          :isSelected="videoGame === game.id"
          size="sm"
          containerClassName="w-full h-30"
          titleClassName="text-md uppercase pt-1"
          @onClick="handleVideoGameSelect(game)"
        />
      </div>
    </div>

    <!-- Game Mode Selection -->
    <div v-if="selectedGameModes.length > 0">
      <label for="game-mode" class="block text-sm font-medium mb-1 text-secondary">
        {{ $t('gamerInfoStep.gameMode.label') }}
      </label>
      <div class="grid grid-cols-2 gap-4">
        <OptionCard
          v-for="mode in selectedGameModes"
          :key="mode.id"
          :title="mode.name"
          color="blue"
          :isSelected="gameMode.id === mode.id"
          size="sm"
          containerClassName="w-full h-30"
          titleClassName="text-md uppercase pt-1"
          @onClick="handleGameModeSelect(mode)"
        />
      </div>
    </div>

    <!-- Clubs Information -->
    <div v-if="isClubsMode" class="space-y-4">
      <p class="mt-1 text-sm text-tertiary">
        {{ $t('gamerInfoStep.clubInfo.description') }}
      </p>
      <div>
        <label for="club-name" class="block text-sm font-medium mb-1 text-secondary">
          {{ $t('gamerInfoStep.clubInfo.name.label') }}
        </label>
        <input
          id="club-name"
          v-model="clubName"
          type="text"
          :placeholder="$t('gamerInfoStep.clubInfo.name.placeholder')"
          ref="clubNameInput"
          class="w-full p-2 rounded bg-primary border border-tertiary/30 text-white"
        />
      </div>
      <div>
        <label for="club-contact" class="block text-sm font-medium mb-1 text-secondary">
          {{ $t('gamerInfoStep.clubInfo.contact.label') }}
        </label>
        <input
          id="club-contact"
          v-model="clubContact"
          type="text"
          :placeholder="$t('gamerInfoStep.clubInfo.contact.placeholder')"
          class="w-full p-2 rounded bg-primary border border-tertiary/30 text-white"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import OptionCard from './OptionCard.vue'

export default {
  name: 'VideoGameSelection',

  components: {
    OptionCard,
  },

  computed: {
    ...mapState('multiStepForm', {
      formData: (state) => state.formData,
    }),
    ...mapGetters('multiStepForm', ['VIDEOGAMES']),

    videoGames() {
      return this.VIDEOGAMES
    },

    videoGame: {
      get() {
        return this.formData.videoGame
      },
      set(value) {
        this.updateFormData({ field: 'videoGame', value })
      },
    },

    selectedGameModes() {
      const selectedGame = this.videoGames.find((game) => game.id === this.videoGame)
      return selectedGame?.modes || []
    },

    gameMode: {
      get() {
        return this.formData.gameMode || { id: null, clubName: '', clubContact: '' }
      },
      set(value) {
        this.updateFormData({ field: 'gameMode', value })
      },
    },

    clubName: {
      get() {
        return this.gameMode.clubName
      },
      set(value) {
        this.updateFormData({
          field: 'gameMode',
          value: { ...this.gameMode, clubName: value },
        })
      },
    },

    clubContact: {
      get() {
        return this.gameMode.clubContact
      },
      set(value) {
        this.updateFormData({
          field: 'gameMode',
          value: { ...this.gameMode, clubContact: value },
        })
      },
    },

    isClubsMode() {
      return this.gameMode.id === 'clubs'
    },
  },

  methods: {
    ...mapActions('multiStepForm', ['updateFormData']),

    handleVideoGameSelect(game) {
      this.updateFormData({
        field: 'videoGame',
        value: game.id,
      })
    },

    handleGameModeSelect(mode) {
      this.updateFormData({ field: 'gameMode', value: { id: mode.id } })

      if (mode.id === 'clubs') {
        this.$nextTick(() => {
          this.$refs.clubNameInput && this.$refs.clubNameInput.focus()
        })
      }
    },
  },
}
</script>
