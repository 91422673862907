<template>
  <svg
    :width="size"
    :height="size"
    :viewBox="viewBox"
    :aria-hidden="!title"
    :role="role"
  >
    <title v-if="title">{{ title }}</title>
    <path :d="path" :fill="color" />
  </svg>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    path: {
      type: String,
      required: true,
    },
    size: {
      type: [Number, String],
      default: 24,
    },
    color: {
      type: String,
      default: 'currentColor',
    },
    title: {
      type: String,
      default: null,
    },
  },
  computed: {
    viewBox() {
      return '0 0 24 24'
    },
    role() {
      return this.title ? 'img' : 'presentation'
    },
  },
}
</script>
