var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"space-y-4"},[_c('div',{staticClass:"mb-8"},[_c('p',{staticClass:"text-white text-sm mb-4"},[_vm._v(_vm._s(_vm.$t("personalInfoStep.title")))]),_c('div',{staticClass:"bg-tertiary bg-opacity-25",style:(_vm.separatorStyle)})]),_c('div',{staticClass:"space-y-4"},[_c('div',[_c('label',{staticClass:"block text-sm font-medium mb-1 text-secondary",attrs:{"for":"full-name"}},[_vm._v(_vm._s(_vm.$t("personalInfoStep.name.label")))]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.name.$model),expression:"$v.name.$model",modifiers:{"trim":true}}],ref:"nameField",class:[
          'w-full p-2 rounded bg-primary text-white text-xl',
          { 'border border-red-500': _vm.showFieldError('name') }
        ],attrs:{"id":"full-name","type":"text","inputmode":"text"},domProps:{"value":(_vm.$v.name.$model)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.name, "$model", $event.target.value.trim())},_vm.validateFields],"blur":[function($event){return _vm.touchField('name')},function($event){return _vm.$forceUpdate()}]}}),(_vm.showFieldError('name'))?_c('div',{staticClass:"mt-1 text-sm text-red-500"},[(!_vm.$v.name.required)?_c('span',[_vm._v(_vm._s(_vm.$t("personalInfoStep.name.error.required")))]):(!_vm.$v.name.minLength)?_c('span',[_vm._v(_vm._s(_vm.$t("personalInfoStep.name.error.minLength")))]):(!_vm.$v.name.maxLength)?_c('span',[_vm._v(_vm._s(_vm.$t("personalInfoStep.name.error.maxLength")))]):_vm._e()]):_vm._e()]),_c('div',[_c('label',{staticClass:"block text-sm font-medium mb-1 text-secondary",attrs:{"for":"email"}},[_vm._v(_vm._s(_vm.$t("personalInfoStep.email.label")))]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.email.$model),expression:"$v.email.$model",modifiers:{"trim":true}}],class:[
          'w-full p-2 rounded bg-primary text-white text-xl',
          { 'border border-red-500': _vm.showFieldError('email') || _vm.emailInUse },
          { 'opacity-75 cursor-not-allowed': _vm.isEmailLocked }
        ],attrs:{"id":"email","type":"email","inputmode":"email","disabled":_vm.isEmailLocked},domProps:{"value":(_vm.$v.email.$model)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.email, "$model", $event.target.value.trim())},_vm.validateFields],"blur":[_vm.validateEmail,function($event){return _vm.$forceUpdate()}]}}),(_vm.showFieldError('email') || _vm.emailInUse)?_c('div',{staticClass:"mt-1 text-sm text-red-500"},[(!_vm.$v.email.required)?_c('span',[_vm._v(_vm._s(_vm.$t("personalInfoStep.email.error.required")))]):(!_vm.$v.email.email)?_c('span',[_vm._v(_vm._s(_vm.$t("personalInfoStep.email.error.format")))]):(_vm.emailInUse)?_c('span',[_vm._v(_vm._s(_vm.$t("personalInfoStep.email.error.inUse")))]):_vm._e()]):_vm._e()]),_c('div',[_c('label',{staticClass:"block text-sm font-medium mb-1 text-secondary",attrs:{"for":"country"}},[_vm._v(_vm._s(_vm.$t("personalInfoStep.country.label")))]),_c('v-select',{class:['style-chooser text-white', { 'error-select': _vm.showFieldError('selectedCountry') }],attrs:{"id":"country","options":_vm.countries,"label":"label","placeholder":_vm.$t('personalInfoStep.country.placeholder')},on:{"input":_vm.handleCountryChange,"blur":function($event){return _vm.touchField('selectedCountry')}},model:{value:(_vm.selectedCountry),callback:function ($$v) {_vm.selectedCountry=$$v},expression:"selectedCountry"}}),(_vm.showFieldError('selectedCountry'))?_c('div',{staticClass:"mt-1 text-sm text-red-500"},[_c('span',[_vm._v(_vm._s(_vm.$t("personalInfoStep.country.error.required")))])]):_vm._e()],1),_c('div',[_c('label',{staticClass:"block text-sm font-medium mb-1 text-secondary",attrs:{"for":"whatsapp"}},[_vm._v(_vm._s(_vm.$t("personalInfoStep.whatsapp.label")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.whatsapp),expression:"whatsapp"}],ref:"whatsappField",class:[
          'w-full p-2 rounded bg-primary text-white text-xl',
          { 'border border-red-500': _vm.showFieldError('whatsapp') }
        ],attrs:{"id":"whatsapp","type":"tel","inputmode":"numeric","pattern":"[0-9]*","placeholder":_vm.$t('personalInfoStep.whatsapp.placeholder')},domProps:{"value":(_vm.whatsapp)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.whatsapp=$event.target.value},_vm.handleWhatsAppInput],"blur":function($event){return _vm.touchField('whatsapp')}}}),(_vm.showFieldError('whatsapp'))?_c('div',{staticClass:"mt-1 text-sm text-red-500"},[(!_vm.$v.whatsapp.required)?_c('span',[_vm._v(_vm._s(_vm.$t("personalInfoStep.whatsapp.error.required")))]):(!_vm.$v.whatsapp.phoneNumber)?_c('span',[_vm._v(_vm._s(_vm.$t("personalInfoStep.whatsapp.error.format")))]):_vm._e()]):_vm._e()]),_c('div',[_c('div',{staticClass:"flex items-center space-x-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.acceptTerms),expression:"acceptTerms"}],staticClass:"h-4 w-4 rounded border-gray-300",attrs:{"type":"checkbox","id":"acceptTerms"},domProps:{"checked":Array.isArray(_vm.acceptTerms)?_vm._i(_vm.acceptTerms,null)>-1:(_vm.acceptTerms)},on:{"change":[function($event){var $$a=_vm.acceptTerms,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.acceptTerms=$$a.concat([$$v]))}else{$$i>-1&&(_vm.acceptTerms=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.acceptTerms=$$c}},_vm.handleTermsChange]}}),_c('label',{staticClass:"text-sm text-secondary",attrs:{"for":"acceptTerms"}},[_vm._v(" "+_vm._s(_vm.$t('personalInfoStep.terms.label'))+" "),_c('a',{staticClass:"text-accent hover:text-white underline",attrs:{"href":"/terms","target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" "+_vm._s(_vm.$t('personalInfoStep.terms.link'))+" ")])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }